<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5 font-weight-bolder">
      {{ $t('Warehouse List') }}
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/list`,
        data: {
          status: 1
        }
      }"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-fulfillment-warehouses-create' }"
      trash-list-path="settings-fulfillment-warehouses-trash-list"
      :can-show-trash-list="ACCESS_ABILITY_WAREHOUSE"
      :can-create="ACCESS_ABILITY_WAREHOUSE"
      :is-pagination-available="false"
      :is-searchable="false"
    >
      <template #cell(address)="{ data }">
        {{ data.item.street }}, {{ data.item.city }}, {{ data.item.state.name }} {{ data.item.zip_code }}
      </template>

      <template #cell(role)="{ data }">
        {{ data.value.name }}
      </template>
      <template #cell(phone)="{ data }">
        <div
          class="text-center"
        >
          {{ data.value }}
        </div>
      </template>
      <template #cell(two_letter_code)="{ data }">
        <div
          class="text-center"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Set Inactive')"
            icon="LTrashIconKits"
            class="mr-1 cursor-pointer"
            size="32"
            @click="setInActive(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Update')"
            icon="LStockEyeIcon"
            class="cursor-pointer"
            size="32"
            @click="gotoNewPage({ name: 'settings-fulfillment-warehouses-update', params: { id: data.item.id } }, $event)"
          />
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip } from 'bootstrap-vue'
import tableConfig from '../warehouseConfig'

export default {
  name: 'WarehouseList',
  components: {
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      const { id } = data

      this.confirmNotification(this, [id], `${this.MODULE_NAME}/moveToTrash`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        }).finally(() => {
          this.$store.dispatch('horizontalMenu/getWarehousesList')
        })
    },
  },
  setup() {
    const MODULE_NAME = 'warehouse'

    const { tableColumns, ACCESS_ABILITY_WAREHOUSE } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_WAREHOUSE,
    }
  },
}
</script>
