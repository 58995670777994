var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Warehouse List'))+" ")]),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/list"),
      data: {
        status: 1
      }
    },"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-fulfillment-warehouses-create' },"trash-list-path":"settings-fulfillment-warehouses-trash-list","can-show-trash-list":_vm.ACCESS_ABILITY_WAREHOUSE,"can-create":_vm.ACCESS_ABILITY_WAREHOUSE,"is-pagination-available":false,"is-searchable":false},scopedSlots:_vm._u([{key:"cell(address)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.street)+", "+_vm._s(data.item.city)+", "+_vm._s(data.item.state.name)+" "+_vm._s(data.item.zip_code)+" ")]}},{key:"cell(role)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value.name)+" ")]}},{key:"cell(phone)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(two_letter_code)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"title":_vm.$t('Set Inactive'),"icon":"LTrashIconKits","size":"32"},on:{"click":function($event){return _vm.setInActive(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"title":_vm.$t('Update'),"icon":"LStockEyeIcon","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'settings-fulfillment-warehouses-update', params: { id: data.item.id } }, $event)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }